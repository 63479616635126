
























































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Scene from '@/components/Scene.vue'
import { BallInformation } from '@/store/app'
import { ApiHelper } from '@/ApiHelper';
import { AppModule } from '@/store/app';
import { ProjectModule, Project } from '@/store/projects';
import Projects from '@/components/Projects.vue'
import Departments from '@/components/Departments.vue'
import { DepartmentModule, Department } from '@/store/departments';
import Sections from '@/components/Sections.vue'
import { SectionModule, Section } from '@/store/sections';

@Component <Home> ({
  components: {
    Scene,
    Projects,
    Departments,
    Sections
  },
  head: {
    title: {
      inner: "なわてん 2023年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展"
    },
    meta() {
      return [
        { property: 'keyword', content: 'なわてん,大阪電気通信大学,四條畷キャンパス,卒業研究,卒業制作展,総合情報学部,デジタルゲーム学科,情報学科,医療健康科学部', id: 'metakeyword'},
        { property: 'description', content: this.metaDescription, id: 'metadescription'},
        { property: 'og:title', content: document.title, id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: this.metaDescription, id: 'ogdescription'},
        { property: 'og:url', content: document.URL, id: 'ogurl' },
        { property: 'og:image', content: document.URL + 'img/ogp.png', id:'ogimage' },
        { property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
        { property: 'twitter:site', content: '@nawaten_info', id: 'twittersite' },
      ]
    },
  },
})
export default class Home extends Vue {
  private informations: BallInformation[] = [];
  private departments: Department[] = [];
  private sections: Section[] = [];
  private keyword = '';
  private orderby = 'popular'; //default
  private placeholder = '';
  private prevPlaceholder = '';
  private nextPlaceholder = '';
  private phIsMoving = false;
  private cnt = 0;
  private metaDescription = '';
  private total = 0;
  private totalpages = 0;
  private currentpage = 1;

  private scrollToSearch(){
    window.scrollBy({
      top: (this.$refs.search as HTMLElement).getBoundingClientRect().top-30,
      left: 0,
      behavior: "smooth"
    });
  }

  private created(){
    ProjectModule.clearProjects();
    AppModule.setLoaded(true);
    window.addEventListener('scroll', this.handleScroll);
  }

  private destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private mounted(){
    const count = window.innerWidth>1100 ? 35 : 20
    let taxonomy = '';
    ApiHelper.callApi(`wp-json/wp/v2/balls`, 'get', {}, res => {
      const tmp = res.data.sort((a: any, b: any)=>{
        return b.count-a.count
      }).filter((v: any) => { return v.count>0 }).slice(0, count);
      for (const term of tmp) {
        if (term.taxonomy == 'main_theme'){ taxonomy = 'themes'}
        else { taxonomy = 'tags'; }
        this.informations.push({
          title: term.name as string,
          path: `/${taxonomy}/${term.term_id}/`,
          imageUrl: term.theme_icon as string,
          count: term.count as number
        });
      }
      setInterval( this.rotateKeyword, 3000);
    });

    ApiHelper.callApi(`wp-json/wp/v2/bloginfo`, 'get', {}, res => {
      this.metaDescription = res.data["meta_description"];
      this.$emit('updateHead');
    });

    ApiHelper.callApi(`wp-json/wp/v2/department?_embed`, 'get', {}, res => {
      DepartmentModule.setDepartment(res.data.map((v: any)=>{
        return {
          id: v.id,
          description: v.description,
          name: v.name,
          eyecatch: v.acf.eyecatch.url,
          movie: v.acf.movie ? v.acf.movie.url : ""
        } as Department;
      }));
    });

    ApiHelper.callApi(`wp-json/wp/v2/section?_embed`, 'get', {}, res => {
      SectionModule.setSection(res.data.map((v: any)=>{
        return {
          id: v.id,
          description: v.description,
          name: v.name,
          eyecatch: v.acf.eyecatch.url,
          movie: v.acf.movie ? v.acf.movie.url : ""
        } as Section;
      }));
    });

    this.getProjects();
    this.rotateKeyword();
  }

  @Watch('keyword')
  private search(){
    ProjectModule.setLoading(false);
    this.currentpage = 1;
    ProjectModule.clearProjects();
    this.getProjects();
  }

  private getProjects(){
    if(ProjectModule.isLoading)return;
    ProjectModule.setLoading(true);
    let orderParam = '';
    if (this.orderby == 'createdAsc') {
      orderParam = '&orderby=date&order=asc';
    } else if (this.orderby == 'createdDesc') {
      orderParam = '&orderby=date&order=desc';
    }
    ApiHelper.callApi(`wp-json/wp/v2/project?per_page=18&_embed&search=${this.keyword}${orderParam}&page=${this.currentpage}`, 'get', {}, res => {
      ProjectModule.appendProjects(res.data.map((v: any)=>{
        return {
          id: v.id,
          title: v.title.rendered as string,
          eyecatch: v.featured_image_url as string,
          terms: v._embedded['wp:term'] ? v._embedded['wp:term'].flat() : [],
          squareEyecatch: (v.acf.squareEyecatch.sizes?.img640 === undefined) ? v.acf.squareEyecatch.url : v.acf.squareEyecatch.sizes.img640 as string,
        } as Project;
      }));
      this.total = parseInt(res.headers["x-wp-total"]);
      this.totalpages = parseInt(res.headers["x-wp-totalpages"]);
      ProjectModule.setLoading(false);
    });
  }
  //private get informations(){
  //  return BallInformationModule.informations;
  //}
  private switchOrderBy(key: string) {
    ProjectModule.setLoading(false);
    this.orderby = key;
    this.currentpage = 1;
    ProjectModule.clearProjects();
    this.getProjects();
  }
  private rotateKeyword() {
    if (this.cnt >= this.informations.length){
      this.cnt = 0;
    }
    if (this.informations[this.cnt]){
      this.prevPlaceholder = this.placeholder;
      this.phIsMoving = true;
      setTimeout(()=>{
        this.nextPlaceholder = this.informations[this.cnt].title;
      },10)
    }
    this.cnt++;
  }
  private animated() {
    this.placeholder = this.nextPlaceholder;
    this.phIsMoving = false;
  }

  public loadNextPage() {
    if(this.currentpage >= this.totalpages)return;
    this.currentpage += 1;
    this.getProjects();
  }

  public handleScroll() {
    const element = document.querySelector('body');
    if(element == null)return;
    const clientHeight = element.clientHeight;
    const windowHeight = window.innerHeight;
    const scrollTop = element.getBoundingClientRect().top * -1;
    if(scrollTop + windowHeight >= clientHeight){
      this.loadNextPage();
    }
  }
}
